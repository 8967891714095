/* Large screens ----------- */
@media only screen and (min-width : 1824px) {
  /* Styles */
}
/* Desktops and laptops ----------- */
@media only screen and (min-width : 1224px) {
  /* Styles */
}

/* mobile screen size*/
@mixin mobile {
  @media only screen and (max-width:768px) {
    @content;
  }
}
@mixin mobile-sm {
  @media only screen and (max-width:468px) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width:768px) {
    @content;
  }
}
@mixin isRTL {
  div[dir="rtl"] {
    @content;
  }
}
