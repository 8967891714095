/* You can add global styles to this file, and also import other style files */
@import 'size.scss';
:root{
  // --app-primary: #005c8d;
  // --app-primary-hover: #044f78;
  // --app-primary-light: #1f7cae;
  // --app-primary-headings: #076090;
  --app-primary: #008fd4;
  --app-primary-hover: #0287c9;
  --app-primary-light: #008fd4;
  --app-primary-headings: #387ac8;

}

.nextone {
  position: absolute;
  top: 250px;
  right: 63px;
  float: right;
  z-index: 99 !important;

  @media only screen and (max-width : 1224px){
    border: 1px solid;
    padding: 8px 9px;
    border-radius: 50%;
    color: #0de0fe !important;
    background-color: transparent !important;
    right: 0% !important;
    top: 45% !important;
  }
}

.profile-widget {
@include mobile{
  width: 270px !important;
  margin-left: 38px !important;
}
}
.previousone {
  position: absolute !important;
  top: 250px !important;
  z-index: 99 !important;
  left: 63px !important;

  @media only screen and (max-width : 1224px){
    border: 1px solid;
    padding: 8px 9px;
    border-radius: 50%;
    color: #0de0fe !important;
    background-color: transparent !important;
    left: 0% !important;
    top: 45% !important;
  }

}
.autocomplete-container.active {
  z-index: none !important;
}
.autocomplete-container {
  box-shadow: none !important;
}
.ng-autocomplete {
  width: 100%;
  box-shadow: none !important;
  z-index: none !important;
}
.autocomplete-container .input-container input {
  border-radius: 99px;
  height: 62px !important;
  border: 1px solid #8080807d !important;
  @include mobile-sm{
    height: 40px !important;
  }
}
.autocomplete-container .suggestions-container {
  border-radius: 34px !important;
    overflow: hidden !important;
}

.autocomplete-container .input-container .x {
  top: 60% !important;
  right:8% !important;
}

input[type="checkbox"]:checked ,input[type="checkbox"]:checked:hover {
  accent-color: var(--app-primary);
}
.btn-primary{
  background-color: var(--app-primary) !important;
  accent-color: var(--app-primary);
}
.autocomplete-container .suggestions-container.is-visible{
  z-index: 12;
}
.autocomplete-container .input-container .x i{
  margin-right: 5px;
    margin-bottom: -7px;
  @include mobile-sm{
    margin-right: 15px;
    margin-bottom: 7px;
  }
}
.facebook , .gmail{
  @include mobile{
    font-size: 13px;
      height: 40px !important;
      width: 70% !important;
      border-radius: 10px !important;
}
}
.search-row {
  @include mobile{
    display: flex;
    flex-direction: column;
    border-radius: 20px !important;
    .form-group{
        margin-bottom: 2px !important;
        label{
          margin-bottom: 0px;
        }
    }

  }
}
.sliderdiv .carousel .slick-next , .sliderdiv .carousel .slick-prev{
  right: 0% !important;
  left: 0% !important;
  display: none !important;
}
.search-app-btn{
  border-color: inherit !important;
}
